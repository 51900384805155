@import "../../../assets/css/media";

.blow-services {
  padding: 60px 30px 0;

  @include mobile {
    padding: 40px 10px 0;
  }

  .store-search-header {
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;

    @include mobile {
      text-align: center;
      line-height: 1.3;
    }
  }

  .search-field-height {
    height: 100%;
    font-size: 18px;

    @include mobile {
      font-size: 15px;
    }
  }

  .search-button {
    margin-top: -5px;
    background: #54575A;
    border-radius: 0;
    color: #FFFFFF;
    font-size: 13px;
    width: 79px;
    height: 35px;
    text-transform: none;

    &:hover {
      background-color: rgb(58, 60, 62);
    }
  }

  .preferred-store-container {
    background-color: #F7F8F9;
    border-top: 1px solid #D1D1D1;
    padding: 36px 36px 20px 36px;
    margin: 18px 0;

    @include mobile {
      padding: 20px 10px 10px;
    }

    .selected-store {
      font-style: italic;

      .label {
        color: #777777;
        margin-right: 10px;
        font-size: 16px;
      }

      .value {
        font-weight: bold;
        font-size: 18px;
      }
    }

    .search-row {
      padding-top: 20px;

      button {
        background: #54575A;
        border-radius: 0;
        color: #FFFFFF;
        padding: 10px 30px;
        font-size: 15px;
        text-transform: none;

        &:hover {
          background-color: rgb(58, 60, 62);
        }
      }
    }
  }

  .error-msg {
    color: #f44336;
  }
}
