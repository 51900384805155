.dry-styling-page{
    max-width: 1295px;
    margin: 0 auto;

    .banner-section{
        .banner-image{
            cursor: pointer;
            padding: 0
        }
    }
    .slider-row{
        margin: 30px auto 0;
        .slick-list{
            margin: 0;
        }
    }
    .shop-all-kits{
        margin: 50px 0 86px
    }
    
    /* /book appointment section, event section */
    
    /* Style detail modal */
    
    .slick-list, .slick-track {
      touch-action: pan-y;
    }
    
    /* Book styling section */
    .book-styling-section {
      padding: 47px 0 36px;
      margin: 0 0 36px;
      border-bottom: 3px dashed #BDBDBD;
    
      &.secondary {
        margin-bottom: 0;
    
        .text-section {
          background: #F7F8F9;
        }
      }
    
      &.last {
        padding-bottom: 0;
        border-bottom: none;
      }
    
      & > .row {
        align-items: center;
        margin-left: 0;
        margin-right: 0;
      }
    
      .text-section {
        padding: 41px 36px 42px;
        background: #FFFFFF;
        box-shadow: 2px 2px 46px rgba(235, 235, 235, 0.5);
        flex: 0 0 30%;
        max-width: 30%;        
    
        h3 {
            font-size: 42px;
            line-height: 42px;
            margin-bottom: 15px;
            text-transform: uppercase;
        }
    
        span {
          font-family: "URWForm";
          font-weight: 500;
          color: #989898;
          font-size: 17px;
            line-height: 25px;
        }
    
        p {
          font-size: 17px;
          line-height: 147%;
          color: #42413D;
          margin: 15px 0 50px;
        }
    
        button {
          width: 100%;
          padding: 20px 10px;
        }
      }
    
      .video-section {
        display: flex;
        justify-content: center;
        height: 100%;
        padding-left: 20px;
        flex: 0 0 70%;
        max-width: 70%;
      }
    
      .video-wrapper {
        padding: 33px;
        border: 2px solid #E2E2E2;
        filter: drop-shadow(0px 4px 64px #D4E4DE);
        max-width: 100%;
        height: fit-content;
        margin: auto;
    
        .video-player {
          max-width: 100%;
        }
      }

      
      .style-item {
        padding: 16px 15px;
        /*flex: 0 0 25%;*/
        /*max-width: 25%;*/
    
        &:hover .mask {
          display: flex;
        }
    
        .content {
          position: relative;
          border: 2px solid #E2E2E2;
          box-sizing: border-box;
          /*filter: drop-shadow(0px 2px 50px rgba(235, 235, 235, 0.75));*/
        }
    
        img {
          width: 100%;
          height: auto;
        }
    
        .mask {
          background: rgba(0, 0, 0, 0.3);
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: none;
          align-items: center;
          justify-content: center;
          padding: 12px;
    
          img {
            width: 75px;
            cursor: pointer;
          }
    
          .btn-common {
            padding: 20px 10px;
            font-weight: 500;
            font-size: 18px;
            line-height: 25px;
          }
        }
      }
    }
    
  }
  