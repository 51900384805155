
.subscribe-form-email {
  .MuiFormHelperText-root.Mui-error {
    color: #f44336;
    font-size: 14px;
  }
  .MuiButtonBase-root {
    min-width: 20%;
    text-decoration: none;
  }

  .MuiInput-underline::before {
    right: 25%;
  }

  .MuiInputBase-input {
    width: 75%;
    padding-left: 10px;
    padding-right: 10px;

    color: #737373;

    &::placeholder {
        font-style: oblique
    }
  }

  .MuiInput-root {
    justify-content: space-between;
  }
}