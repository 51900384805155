@import '../../assets/css/variables';
@import '../../assets/css/media';

%container_background_colors {
  &.container_backgroundcolor {
    @each $type, $value in $hero_background_colors {
      &__#{$type} {
        background-color: $value;
      }
    }
  }
}

%title_sizes_placeholder {
  &.title_size {
    @each $type, $value in $hero_title_sizes {
      &__#{$type} {
        font-size: em(map-get($value, 'title'));

        + p {
          font-size: em(map-get($value, 'subtitle'));
        }
      }
    }
  }
}

%title_colors_placeholder {
  &.title_color {
    @each $type, $value in $hero_title_colors {
      &__#{$type} {
        color: map-get($value, 'title');

        &.title_dropshadow {
          text-shadow: 2px 2px #{map-get($value, 'shadow')};
        }
      }
    }
  }
}

.homepage {
  background: #fff;

  .slider_section {
    max-width: 1297px;
    margin: 0 auto;
    float: none;
    padding: 15px 0 0;

    @include tablet {
      padding: 15px 15px 40px;
    }

    @include desktop {
      padding: 20px 20px 40px;
    }

    .slick-slider {      
      .slick-list {
        margin: 0;
        
        .slider-inner {
          position: relative;
          
          @extend %container_background_colors;
        }
        
        $vertical_alignment_offset: 5%;
        
        .container {
          &_textalign {
            &__top {
              .text-link-container {
                justify-content: flex-start;
                
                .text-link-inner {
                  margin-top: $vertical_alignment_offset;
                }
              }
            }

            &__middle {
              .text-link-container {
                justify-content: center;
              }
            }

            &__bottom {
              .text-link-container {
                justify-content: flex-end;

                .text-link-inner {
                  margin-bottom: $vertical_alignment_offset;
                }
              }
            }
          }

          &_textjustify {
            &__left {
              .text-link-container {
                align-items: flex-start;
              }
            }

            &__center {
              .text-link-container {
                align-items: center;
              }
            }

            &__right {
              .text-link-container {
                align-items: flex-end;
              }
            }
          }
        }

        .text-link-container {
          display: flex;
          flex-flow: column nowrap;
          position: absolute;
          left: 50%;
          top: 50%;

          @include mobile {
            width: 70%;
            transform: translate(-70%, -50%);
          }

          transform: translate(-50%, -50%);
          width: 100%;

          height: 100%;
          padding: 20px;

          @include tablet {
            padding: 20px 50px;
          }

          @include desktop {
            padding: 20px 70px;
          }

          .text-link-inner {
            max-width: 50%;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            font-size: 1.125vw;
            
            @include customMq('1440px') {
              font-size: 16px;
            }
            
            h3 {
              font-family: 'DINCondensed-Regular';
              font-weight: 400;
              line-height: 1;
              letter-spacing: 0;
              padding: 0;
              text-shadow: none;
              margin-bottom: 15px;

              @extend %title_sizes_placeholder;
              @extend %title_colors_placeholder;
            }

            p {
              line-height: 1.27;
              color: #5c5a54;
              padding: 10px 0 10px;
              font-weight: 400;
            }            
            
            @include largeDesktop {
              max-width: 570px;
            }

            @include mobile {
              top: 0;
              position: relative;
              width: 100%;
              max-width: calc(100% - 80px);
              text-align: center;

              &.content-right,
              &.content-left,
              &.content-center {
                left: 0;
                right: 0;
              }
            }
            
            .book_btn {
              margin: 15px auto 0;
              @include mobile {
               display: none;
              }
            }
            
            //.book_btn {
            //  text-align: center;
            //  width: 200px;
            //  height: 45px;
            //  padding: 0;
            //  margin: 15px auto 0;
            //  text-transform: uppercase;
            //  overflow: hidden;
            //  line-height: 45px;
            //  font-size: 21px;
            //  
            //  a {
            //    text-decoration: none;
            //    font-size: 21px;
            //    line-height: normal;
            //  }
            //
            //  @include mobile {
            //    width: 140px;
            //    height: 35px;
            //    line-height: 35px;
            //    
            //    a {
            //      font-size: 14px;
            //      line-height: 35px;
            //    }
            //  }
            //
            //  @include mediumMobile {
            //    width: 120px;
            //    height: 30px;
            //    line-height: 30px;
            //    
            //    a {
            //      font-size: 12px;
            //      line-height: 30px;
            //    }
            //  }
            //
            //  @include tablet {
            //    width: 150px;
            //    height: 38px;
            //    line-height: 38px;
            //    
            //    a {
            //      font-size: 16px;
            //      line-height: 38px;
            //    }
            //  }
            //
            //  @include smallDesktop {
            //    width: 180px;
            //    height: 40px;
            //    line-height: 40px;
            //    
            //    a {
            //      font-size: 18px;
            //      line-height: 40px;
            //    }
            //  }
            //}
          }
        }
      }

      .slick-arrow.right {
        right: 15px;

        @include tablet {
          right: 15px;
        }
      }

      .slick-arrow.left {
        left: 15px;

        @include tablet {
          left: 15px;
        }
      }

      .slick-dots {
        display: flex !important;
        justify-content: center;
        bottom: -30px;

        @include mobile {
          bottom: 32px;
        }

        li {
          @include mobile {
            width: 12px;
            height: 12px;
            margin-left: 32px;
            &:first-child {
              margin-left: 0;
            }
          }
          button {
            &::before {
              color: black;
              opacity: 0.5;
            }
            @include mobile {
              width: 12px;
              height: 12px;
              border: solid 1px #42413D;
              background: transparent;
              border-radius: 50%;

              &::before {
                content: "";
                display: none;
              }
            }
          }

          &.slick-active button {
            &::before {
              color: black;
              opacity: 1;
            }
            @include mobile {
              border: none;
              background: #FFDD30;
            }
          }

        }
      }
  
      .slick-list {       
        @include mobile {
          margin-left: 0;
          margin-right: 0;
        }
    
        .inner-slide-item {
    
          & > div {
            margin: 5px;
          }
        }
    
        .slick-slide {
          .slider-wrapper {
            filter: drop-shadow(0px 0px 25px #D4E4DE);
          }
    
          @include mobile {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
    
            .slider-wrapper {
              filter: drop-shadow(0px 0px 5px #D4E4DE);
            }
          }
        }
    
        & .slider-wrap:nth-child(2n) > .row {
          justify-content: flex-start;
    
        }
    
        & .slider-wrap:nth-child(2n + 1) > .row {
          justify-content: flex-end;
        }
      }
    }
  }

  .middle_section > * {
    max-width: 1295px;
    margin-left: auto !important;
    margin-right: auto !important;
    float: none !important;

    &.mobile_app {
      max-width: none;
      width: 100%;

      & > .container {
        max-width: 1295px !important;
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }
  }

  .outer_border {
    border: 2px solid #ccc;
    padding: 50px 50px 40px 50px;
    margin-top: 70px;
    width: 100%;
    float: none !important;

    .bg_strip {
      width: 100%;
      background-image: url(../../assets/images/heading_center_bg.png);
      float: left;
      margin: 0;
      padding: 10px 0 10px 0;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: -73px;

      p {
        font-family: 'MrsEavesOT-Roman';
        font-size: 18.8px;
        font-weight: 500;
        margin: 0 0 -4px;
        color: #42413D;
        letter-spacing: 2.01px;
        text-transform: uppercase;
      }

      @include tablet {
        top: -63px;
      }

      @include mobile {
        top: -50px;
      }

      @include mediumMobile {
        top: -40px;

        p {
          font-size: 12px;
        }
      }
    }

    .first_box {
      width: 100%;
      height: 100%;
      float: left;
      margin: 0;
      padding: 0;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &:hover {
        box-shadow: 1px 13px 18px 0 #f7f8f9;
        transition-duration: 2s;
      }

      .heading_and_details {
        min-height: 286px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 20px;

        h3 {
          font-size: 22px;
          line-height: 26px;
          margin: 0 0 10px;
          text-align: center;
        }

        p {
          font-size: 15px;
          line-height: 20px;
          text-align: center;
          min-height: 60px;

          @include mobile {
            min-height: 0;
          }
        }

        .book_lnk {
          width: 100%;
          float: left;
          margin: 0;
          padding: 50px 0;
          text-align: center;

          a, button {
            font-family: 'URWForm';
            font-size: 15px;
            line-height: 17px;
            font-weight: 400;
            display: block;
            width: 50%;
            margin: 0 auto;
            color: #fff;
            background: #42413D;
            border: none;
            padding: 13px 20px 10px;
            text-transform: uppercase;
            letter-spacing: 1px;
            cursor: pointer;
          }
          > a:hover, button:hover {
            text-decoration: underline;
          }
        }
      }
    }

    @include tablet {
      padding: 40px 20px 30px;

      .first_box {
        padding: 20px 10px;
      }
    }

    @include smallDesktop {
      padding: 50px 30px 40px;

      .first_box {
        padding: 20px 10px;
      }
    }

    .icon_area {
      cursor: pointer;
    }

    @include mobile {
      padding: 0;
      margin-top: 70px;
      float: left;
      border-width: 0;

      .row > div {
        padding-bottom: 10px;
      }

      .first_box {
        padding: 30px 20px;
        margin: 0 0 10px 0;

        .icon_area {
          display: flex;
          flex-direction: row;
          cursor: pointer;

          .icon_img {
            width: 85px;

            img {
              padding-top: 30px;
              margin-right: 20px;
              box-sizing: border-box;
              width: 62px
            }
          }
        }
      }
    }
  }

  .border-line {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px 30px;
    background-image: radial-gradient(#dbdbdb 25%, transparent 25%);
    background-position: 0 center;
    background-size: 12px 12px;
    background-repeat: repeat-x;
    position: relative;
    min-height: 12px;
    max-width: 1265px;
    margin: 30px auto;

    .border-line-img {
      padding: 0 12px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      img {
        width: 23px;
        height: 18px;
      }
    }
  }

  .social_section {
    padding: 60px 0 20px;

    h2.dry_bar {
      font-size: 42px;
      font-weight: 400;
      color: #42413D;
      font-family: 'DINCondensed-Regular';
      padding-left: 19px;

      span {
        font-size: 17px;
        line-height: 18px;
        font-weight: 400;
        padding: 0 0 0 20px;
        font-family: 'URWForm';
      }

      @include mobile {
        span {
          display: block;
          padding-left: 0;
          margin-top: 10px;
        }
      }
    }

    h3.follow_instagram {
      font-weight: 600;
      font-size: 20px;
      padding-right: 34px;

      span {
        position: relative;
        top: 3px;
        padding: 0 0 0 13px;
        color: #42413D;
      }
    }
  }

  @include mobile {
    ol.carousel-indicators.slid_tabs {
      bottom: 20px;
      margin-bottom: 5px;
    }
  }

  .container {
    max-width: 100% !important;
  }

  .the_styles_section {
    .the_styles {
      h3 {
        font-family: 'DINCondensed-Regular';
        //font-weight: bold;
        font-weight: 400;
        font-size: 73px;
        line-height: 88px;
        text-align: center;

        color: #42413D;
      }

      a.see_all {
        border: none;
        height: 37px;
        padding: 10px 30px;
        font-size: 13px;
        color: #42413D;
        background: #ffdd30;
      }
    }

    .card-img-overlay.t_img {
      bottom: 30px;
      left: 30px;
      top: auto;
      padding: 0;

      span {
        //font-weight: bold;
        font-family: 'DINCondensed-Regular';
        font-size: 28px;
        color: #42413D;
      }
    }

    .styles-show {
      @include mobile {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  .slick-arrow {
    position: absolute;
    z-index: 10;
    top: calc(50% - 20px);
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }
  
  .full_with_part {
    width: 100%;
    float: left;
    margin: 50px 0 70px;
    padding: 0;
    background-color: #f0d6d9;

    .first_with_row {
      width: 33.33%;
      float: left;
      margin: 0;
      padding: 0;
      position: relative;

      img {
        width: 100%;
      }

      .heading_text {
        width: 100%;
        float: left;
        text-align: center;
        margin: 0;
        padding: 10% 70px;
        position: absolute;

        h3 {
          font-size: 50px;
          line-height: 45px;
          font-weight: bold;
          font-family: 'DINCondensed-Regular';
          color: #42413D;
          padding: 50px 0 10px 0;
        }

        p {
          font-size: 18px;
          line-height: 24px;
          padding-top: 15px;
          font-family: 'URWForm';
          color: #42413D;
          font-weight: 400;
        }

        .icon_img {
          display: flex;
          justify-content: center;

          img {
            width: 135px;
          }
        }

        .bnt_see_more {
          width: 100%;
          float: left;
          margin: 0;
          padding: 50px 0;
          text-align: center;

          a {
            color: #42413D;
            background: #ffdd30;
            font-family: 'URWForm';
            font-size: 15px;
            line-height: 18px;
            font-weight: 700;
            border: none;
            padding: 13px 40px 10px;
            text-transform: uppercase;
            letter-spacing: 1px;
          }
        }
      }


      @include smallDesktop {
        .heading_text {
          h3 {
            font-size: 50px;
            line-height: 45px;
          }

          p {
            font-size: 18px;
            line-height: 24px;
            padding-top: 0;
          }

          .bnt_see_more a {
            font-size: 15px;
            line-height: 18px;
          }

          .icon_img img {
            width: 64px;
          }
        }
      }

      @include tablet {
        .heading_text {
          padding: 25px;

          h3 {
            font-size: 35px;
            line-height: 35px;
          }

          p {
            font-size: 16px;
            line-height: 22px;
            padding-top: 0;
          }

          .bnt_see_more a {
            font-size: 14px;
            line-height: 14px;
          }

          .icon_img img {
            width: 64px;
          }
        }
      }

      @include mobile {
        width: 100%;
      }

      @include mediumMobile {
        .heading_text {
          padding: 25px;

          h3 {
            font-size: 35px;
            line-height: 35px;
          }

          p {
            font-size: 16px;
            line-height: 22px;
            padding-top: 0;
          }

          .bnt_see_more a {
            font-size: 14px;
            line-height: 14px;
          }

          .icon_img img {
            width: 64px;
          }
        }
      }
    }
  }

  .mobile_app {
    position: relative;
    background-color: #F9F9F9;
    padding: 65px 0 0;

    h3 {
      font-family: "URWForm";
      font-size: 20px;
      line-height: 32px;
      color: #42413D;
      letter-spacing: 2.01px;
      text-align: center;

      img {
        padding: 0 20px
      }
    }

    p {
      font-family: "URWForm";
      font-size: 17px;
      line-height: 23px;
      color: #42413D;
      text-align: center;
    }

    .app-store {
      padding-top: 30px;

      .app-store-btns {
        text-align: center;

        .app-store-btn {
          margin: 40px 5px 0
        }
      }
    }

    .direct-link {
      border: 1px solid #AFAFAF;
      padding: 60px;

      .mobile-phone-icon {
        width: 38px;
        height: 38px;
        position: absolute;
        top: -19px;
        left: calc(50% - 19px);
        background-color: #FAFAFA;
      }

      h3 {
        line-height: 28px;
        letter-spacing: normal;
        font-weight: normal;
        text-align: center;
      }

      span {
        top: 0
      }
    }

    .phone-form {
      display: flex;
      flex-direction: row;
      margin-top: 30px;

      .phone-number {
        width: 100%;
        height: 40px;
        margin-right: 20px
      }

      .phone-submit {
        background: #54575A;
        border-radius: 0px;
        color: #FFFFFF;
        font-size: 13px;
        height: 40px;
        text-transform: none;

        &:hover {
          background-color: rgb(58, 60, 62);
        }
      ;
      }
    }
  }

  .mobile_app {

    @include mobile {
      padding: 30px 0;

      .mobile-phone-img {
        display: none;
      }
    }

    .container > .row {
      padding-left: 50px;
      padding-right: 50px;
    }

    .direct-link {
      @include smallDesktop {
        padding: 40px 15px;

        button {
          font-size: 11px;
        }
      }

      @include mobileAndTablet {
        display: none;
      }
    }
  }
}

.the_styles_section {
  .styles-show {
    @include mobile {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.slick-slide img {
  //max-height: 450px;

  @include smallDesktop {
    //max-height: 400px;
  }

  @include tablet {
    //max-height: 350px;
  }

  @include mobile {
    //max-height: 300px;
  }
}

.slick-arrow {
  position: absolute;
  z-index: 10;
  top: calc(50% - 12px);
  border: none;  
  background: none;
  &:focus {
    outline: none;
  }

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
}

.slick-list {
  margin-left: 40px;
  margin-right: 40px;

  @include mobile {
    margin-left: 15px;
    margin-right: 15px;
  }

  .inner-slide-item {

    & > div {
      margin: 5px;
      box-shadow: 0 8px 16px 0 #ccc;
    }
  }

  & .slider-wrap:nth-child(2n) > .row {
    justify-content: flex-start;

  }

  & .slider-wrap:nth-child(2n + 1) > .row {
    justify-content: flex-end;
  }
}

#alert-dialog-title {
  button {
    margin: "-6px -20px 0px 64px";
    cursor: "pointer";
    border: none;
    background: none;
  }
}

.dialog-content {
  @include mobile {
    padding: 5px;
  }

  .video-wrapper {
    padding: 5px;
    border: 2px solid #E2E2E2;
    filter: drop-shadow(0px 4px 64px #D4E4DE);
    max-width: 100%;
    height: fit-content;
    margin: auto;
    
    .video-player {
      max-width: 100%;
      width: 756px !important;
      height: 399px !important;

      @include mobile {
        width: 500px !important;
        height: 260px !important;
      }
    }
  }
}

.visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  top: auto;
  white-space: nowrap;
  width: 1px;
}
