@use "sass:math";

$default-size: 16;

@function em($pixels, $default: $default-size) {
  @return #{math.div($pixels, $default)}em;
}

$hero_background_colors: (
  'default': #5f5e5b,
  'white': #ffffff,
  'pink': #edd8da,
  'dark_pink': #cf5772,
  'yellow': #ffdd30,
  'blue': #b1c8e3,
  'dark_blue': #3e6da8,
  'grey': #42413d,
  'black': #000000,
  'transparent': transparent
);

$hero_title_sizes: (
  'default': ('title': 80, 'subtitle': 32),
  'small': ('title': 48, 'subtitle': 21),
  'medium': ('title': 65, 'subtitle': 27.5),
  'large': ('title': 75, 'subtitle': 27),
  'extra_large': ('title': 80, 'subtitle': 32)
);

$hero_title_colors: (
  'default': ('title': #5f5e5b, 'shadow': #e1e0dc),
  'white': ('title': #ffffff, 'shadow': #999999),
  'pink': ('title': #edd8da, 'shadow': #8e8283),
  'dark_pink': ('title': #cf5772, 'shadow': #e29aaa),
  'yellow': ('title': #ffdd30, 'shadow': #99851d),
  'blue': ('title': #b1c8e3, 'shadow': #6a7888),
  'dark_blue': ('title': #3e6da8, 'shadow': #e3ebf3),
  'grey': ('title': #42413d, 'shadow': #8e8d8b),
  'black': ('title': #000000, 'shadow': #666666),
  'transparent': ('title': transparent, 'shadow': #5f5e5b)
);
