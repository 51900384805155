.MuiButtonBase-root {
  &.MuiAccordionSummary-root {
    &.Mui-expanded {
      border-bottom: 1px solid #d6d6d6;
    }
  }
}

.structured__table{
    margin-bottom:25px;
}

.root h5{
    margin-top:20px;
}

.faq-page {

    .paragraph_faq p {
        float: none;
        /*display: inline;*/
    }

    .faq_bg {
        float: none;
        padding: 0;

        @media screen and (max-width: 767px) {
            padding: 0;
        }

        img {
            min-height: 200px;
        }
    }

    .faq_heading {
        text-transform: uppercase;
        font-size: 32px;
    }

    .inner_bg_views {
        padding-top: 48px;

        .find_answer {
            p {
                font-size: 20px;
                line-height: 1.2;
                color: #42413D;
            }

            h1 {
                font-size: 42px;
                line-height: 50px;
                margin-bottom: 14px;
            }
        }

        @media screen and (max-width: 768px) {
            padding-top: 30px;

            .find_answer {
                padding: 0 10px;

                h1 {
                    margin-bottom: 22px;
                    line-height: 1;
                }

                p {
                    margin-bottom: 25px;
                    font-size: 15px;
                    line-height: 24px;
                }
            }
        }
    }
} 