
.title-container {
  padding-top: 48px;
  padding-bottom: 45px;

  h5 {
    font-size: 31px;
    line-height: 1;
    font-family: DINCondensed-Regular;
    font-weight: 400;
    padding: 0 21px;


  p {
    font-family: URWForm;
    font-size: 15px;
    text-transform: uppercase;
    color: #42413D;
  }

    @media screen and (min-width: 768px) {
      font-size: 42px;
      line-height: 51px;
    }
  }
}
.static-page {
  max-width: 1295px;
  margin: 0 auto 40px;

  p {
    line-height: 28px;
    font-size: 18px;
    margin-bottom: 20px;
  }

  h3{

  }

  hr{
    margin-bottom: 40px;
  }
  .card-body {
    background: #FFFFFF;
    box-shadow: 2px 2px 46px rgba(235, 235, 235, 0.5);
    margin-left: 21px;
    margin-right: 17px;
    padding: 23px 19px 86px 21px;

    @media screen and (min-width: 768px) {
      margin-left: 35px;
      margin-right: 35px;
      padding: 67px 35px 86px;
    }
  
    .divider {
      border-bottom: 1px solid #D1D1D1;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  
    p {
      font-family: URWForm;
      color: #42413D;
    }
  
    .top-description {
      padding-left: 3px;
  
      p {
        line-height: 22px;
        font-size: 15px;
        margin-bottom: 20px;
        @media screen and (min-width: 768px) {
          font-size: 18px;
          line-height: 45px;
          margin-bottom: 0;
        }
      }
    }
  
    .section {
      padding-left: 10px;

      @media screen and (min-width: 768px) {
        margin-bottom: 36px;
      }
  
      p {
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 15px;

        &.information {
          margin-bottom: 20px;
          
          @media screen and (min-width: 768px) {
            font-size: 18px;
            line-height: 32px;
          }
        }

        @media screen and (min-width: 768px) {
          font-size: 20px;
          line-height: 2.4;
        }
      }

      a.link {
        color: #42413D;
        text-decoration: underline;
        font-weight: 700;
      }
    }
  
    .items-description {
  
      .card-item {
        margin-top: 10px;
        margin-bottom: 10px;
        .item-header {
          background: #F7F7F7;
          padding: 14px 23px;
          font-family: MrsEavesOT-Roman;
          font-size: 20px;
          line-height: 45px;
          /* or 225% */
  
          display: flex;
          align-items: center;
          text-transform: uppercase;
  
          color: #42413D;
          border-bottom: 2px solid #ddd;
        }
      }
    }
  }

  .middle-section {
    position: relative;

    .end {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 86px;
      background: linear-gradient(180deg, #E5E5E5 0%, rgba(229, 229, 229, 0) 90.45%);
      opacity: 0.25;
      transform: rotate(-180deg);
    }
  }
}

