@mixin btn(
  $height: 40px,
  $width: 220px,
  $font-size: 14px
) {
  height: $height;
  min-width: $width;
  font-size: $font-size;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  padding: 0 30px;
  text-decoration: none;
  appearance: none;

  &:hover {
    text-decoration: none;
  }
}
