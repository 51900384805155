$breakpoint: 767px;

.searchMegaMenu-input {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .ais-SearchBox-form {
    flex-basis: 100%;
    position: relative;

    .ais-SearchBox-input {
      width: 100%;
      border: none;
      border-bottom: 1px solid #979797;
      background-color: #F9F9F9;
      padding: 4px 4px 4px 0;

      &:focus {
        outline: none;
      }
    }

    .ais-SearchBox-submit {
      display: none;
    }

    .ais-SearchBox-reset {
      display: none;
      border: none;
      background-color: #F9F9F9;
      position: absolute;
      right: 0;
      top: 3px;
      cursor: pointer;
    }
  }

  @media (max-width: $breakpoint) {
    position: absolute;
    top: 50px;
    left: 0;

    .ais-SearchBox-form {
      flex-basis: 95%;
      z-index: 1;

      .ais-SearchBox-input {
        padding: 10px 4px 4px 30px;
        background-color: #ffffff;
      }
    }
  }
}

.ais-Hits-list {
  @media (max-width: $breakpoint) {
    display: flex;

    .ais-Hits-item {
      width: 115px;
    }
  }
}

.videoSectionMobile {
  @media (max-width: $breakpoint) {
    .ais-Hits-list {
      .ais-Hits-item {
        width: 185px;
      }
    }
  }
}

.locationSectionMobile {
  max-height: 150px;

  .ais-Hits-list {
    @media (max-width: $breakpoint) {
      display: block;
    }
  }
}
