@import "../../../assets/css/media";

/**
 * The modal dialog classes
 */
.worldpay-modal {
    /* This way it could be display flex or grid or whatever also. */
    display: flex;
    /* Probably need media queries here */
    width: 95%;
    max-width: 1024px;
    height: 95%;
    max-height: 1000px;
    position: fixed;
    z-index: 100;
    left: 50%;
    top: 50%;
    /* Use this for centering if unknown width/height */
    transform: translate(-50%, -50%);
    background: white;
    @include mobile{ 
        height:650px;
    }
}

.worldpay-modal-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
}

/**
 * The close icon and title
 */
.payment-card-close-icon {
    cursor: pointer;
    font-size: 24px;
    margin: 10px 20px -18px 0px;
}

.payment-card-close-button-row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 0px;
}

.MuiSelect-select {
    padding: 8px 0 8px;
}

.payment-card-title {
    text-transform: uppercase;
    font-size: 32px;
    margin-bottom: 1px;
    text-align: center;
    font-family: DINCondensed-Regular;
    //font-weight: bold;
    z-index: -1;
    margin-top: 0px;

    @include mobile {
        font-size: 24px;
    }
}

/**
 * The card image classes.
 */
.payment-card-image-row {
    display: flex;
    flex-direction: column;
}

.payment-card-image-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 140px;

    @include mobile {
        height: 110px;
        }

    @include mobile {
        height: 140px;
    }
}

.payment-card-image {
    width: 250px;
}

.card-image {
    width: 200px;

    @include mobile {
        width:150px;
    }
}

.payment-card-content {
    position: absolute;
    top: 25px;
}

.payment-card-image-text {
    white-space: nowrap;
    font-weight: 500;
    font-size: 15px;

    @include mobile {
        font-size: 12px;
    }

}

.payment-card-image-number {
    margin: 9px 0px 0px 0px;
    cursor: pointer;
    font-weight: 600;
}
/**
 * The form layout classes
 */
.payment-card {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: none;
    border: none;
    border-radius: 0;
}

.card-image {
    height: 160px;
    @include mobile {
        height: 120px;
    }
}

.payment-card .checkout {
    display: flex;
    padding: 0px 16px 16px 16px;
    flex-direction: column;
}

.payment-card .checkout .card-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    gap: 5px;

    @include mobile {
        flex-direction: column;
        margin-bottom:0px;
        gap: 0;
        margin-bottom: 1px;
    }
}

.payment-card .checkout .label {
    margin: 0px 0px 0px;
}

.payment-card .checkout .card-col {
    display: flex;
    flex-direction: column;
    flex: 1;

    @include mobile {
        margin-bottom: 5px;
    }
}
/**
 * The form field classes
 */
.payment-card .checkout .field {
    height: 35px;
    border-bottom: 1px solid #767676;
    padding: 5px;
    box-sizing: border-box;

    &:hover {
        border-bottom: 1px solid black;
    }

    .field-error {
        border-bottom: 1px solid red;
    }
}

.MuiSelect-select > option:checked {
    background-color: purple !important;
}

.payment-card .checkout .label.field-error {
    color: red;
}
/**
 * The form field label classes
 */
.payment-card .checkout .label {
    color: #767676;
    font-size: 14px;
    font-family: URWForm;
    line-height: 20px;
    @include mobile {
        line-height:10px;
    }
}
/**
 * The form message classes
 */

.payment-card .checkout .card-row.general-errors {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0px;
}

.payment-card .success#general-success-msg {
    padding-top: 10px;
    color: green;
}

.payment-card .warning#general-warning-msg {
    padding-top: 10px;
    color: orange;
}

.payment-card .error#general-error-msg {
    padding-top: 10px;
    color: red;
    font-size: 12px;
}
/**
 * 3DS DDC/Challenge iframe
 */

#worldpay-iframe-holder {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}

#worldpay-challenge-iframe-holder {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

#worldpay3DSChallengeForm {
    border: none;
    background-color: white;
}

.worldpay-challenge-iframe-close-button-row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 20px;
    width: 100%;
}

.worldpay-challenge-iframe-title {
    text-transform: uppercase;
    font-size: 32px;
    margin-bottom: 30px;
    text-align: center;
    font-family: DINCondensed-Regular;
    //font-weight: bold;
    width: 100%;
}
