.thestyles-page.mw-1295{
  max-width: 1295px;


  .banner-image img {
    min-height: 200px;
    width: 100%;
    
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .book-appointment-section {
    & > .row {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 30px;
      padding-bottom: 50px;
      margin-left: 0;
      margin-right: 0;

      h1 {
        text-align: center;
        padding: 0 10px;
      }
  
      p {
        font-size: 22px;
        line-height: 30px;
        color: #42413D;
        margin-top: 35px;
        margin-bottom: 40px;
        max-width: 900px;
        text-align: center;
      }
  
      button {
        color: #42413D;
        padding: 20px 98px;
      }
    }
  
    .slider-row {
      filter: drop-shadow(0px 2px 50px rgba(235, 235, 235, 0.75));
    }
  }
  
  .appointment-item .content {
    position: relative;
    box-sizing: border-box;
  
    .mask {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 55px;
      background: rgba(254, 254, 254, .8);
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
  
      .overlay {
        text-transform: uppercase;
      }
    }
  
    span {
      font-family: 'DINCondensed-Regular';
      //font-weight: bold;
      font-size: 26px;
      line-height: 31px;
      text-align: center;
      color: #42413D;
      margin-top: 15px;
      margin-bottom: 15px;
      top: 0;
    }
  }
  
  /* /book appointment section, event section */
  
  
  .slick-list, .slick-track {
    touch-action: pan-y;
  }
  
  /* Book styling section */
  .book-styling-section {
    padding: 47px 0 36px;
    margin: 0 0 36px;
    border-bottom: 3px dashed #BDBDBD;
  
    &.secondary {
      margin-bottom: 0;
  
      .text-section {
        background: #F7F8F9;
      }
    }
  
    &.last {
      padding-bottom: 0;
      border-bottom: none;
    }
  
    & > .row {
      align-items: center;
      margin-left: 0;
      margin-right: 0;
    }
  
    .text-section {
      padding: 41px 36px 42px;
      background: #FFFFFF;
      box-shadow: 2px 2px 46px rgba(235, 235, 235, 0.5);
      flex: 0 0 25%;
      max-width: 25%;
  
      .yellow-bar-title{
        position: relative;
        display: inline-block;
  
        span.yellow-bar{
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 20px;
          background-color: #FFDD30;
          margin: 0;
        }
        h3 {
          display: inline-block;
          margin: 0 15px 20px;
          position: relative;
          font-size: 42px;
          line-height: 42px;
          text-transform: uppercase;
        }
      }    
  
      span {
        font-family: "URWForm";
        font-weight: 500;
        font-size: 18px;
        line-height: 139%;
        color: #989898;
        margin-left: 15px;
        margin-right: 15px;
      }
  
      p {
        font-size: 17px;
        line-height: 147%;
        color: #42413D;
        margin: 15px 15px 80px;
      }
  
      button {
        width: 100%;
        padding: 20px 10px;
      }
    }
  
    .video-section {
      display: flex;
      justify-content: center;
      height: 100%;
      padding-left: 20px;
      flex: 0 0 75%;
      max-width: 75%;
    }
  
    .video-wrapper {
      padding: 33px;
      border: 2px solid #E2E2E2;
      filter: drop-shadow(0px 4px 64px #D4E4DE);
      max-width: 100%;
      height: fit-content;
      margin: auto;
      min-width: 100%;
      
      .video-player {
        max-width: 100%;
      }
    }
  }
  
  
  .book-styling-section {
    .slider-row {
      margin-top: 32px;
      margin-left: -15px;
      margin-right: -15px;
    }
  
    .style-item {
      padding: 16px 15px;
      /*flex: 0 0 25%;*/
      /*max-width: 25%;*/
  
      &:hover .mask {
        opacity: 100;
        display: flex;
      }
  
      .content {
        position: relative;
        border: 2px solid #E2E2E2;
        box-sizing: border-box;
        /*filter: drop-shadow(0px 2px 50px rgba(235, 235, 235, 0.75));*/
      }
  
      img {
        width: 100%;
        height: auto;
      }
  
      .mask {
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        opacity: 0;
        align-items: center;
        justify-content: center;
        padding: 12px;
  
        img {
          width: 75px;
          cursor: pointer;
        }
  
        .btn-common {
          padding: 20px 10px;
          font-weight: 500;
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
  }
  
  .book-appointment-section{
    .slider-row{
      .slick-slider{
        .slick-list{
          margin: 0 20px;
        }
        .slick-arrow{
          position: absolute;
          z-index: 2;
          top:calc(50% - 15px);
          width: 30px;
          height: 30px;
          text-align: center;
          border: none;  
          background: none;
          &:focus {
            outline: none;
          }
          &.left{
            left: -10px;
            cursor: pointer;
          }
          &.right{
            right: -10px;
            cursor: pointer;
          }
        }
        .event-item{
          .content{
            padding: 0 5px;
            h6{
              font-size: 25px;
              line-height: 29px;
            }
            span{
              font-size: 15px;
              line-height: 23px;
            }
          }
        }
        
        .slick-dots{
          left: 0;
          li{
            display: inline-block;
          }
        }
      }
    }
  }
}


  /* Style detail modal */
  
  .style-detail-modal {
  
    .MuiPaper-root {
      padding: 26px 30px 40px;
    }
  
    .style-look-title {
      font-family: DINCondensed-Regular;
      //font-weight: bold;
      font-size: 42px;
      line-height: 50px;
      text-transform: uppercase;
      color: #42413D;
    }
  
    .video-section {
      display: flex;
      justify-content: center;
      height: 100%;
    }
  
    .video-wrapper {
      padding: 10px;
      border: 2px solid #E2E2E2;
      filter: drop-shadow(0px 4px 64px #D4E4DE);
      width: 100%;
      height: fit-content;
      margin: auto;
    }
  
    .row {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 16px;
  
      img {
        width: 20px;
        height: 20px;
      }
    }
  
    .slider-row {
      margin: 16px 0 25px;
  
      .slick-slider {
        padding-left: 0;
        padding-right: 5px;
      }
  
      .slick-list {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  
    .style-item {
      border: 4px solid #FFFFFF;
      box-sizing: border-box;
      filter: drop-shadow(2px 2px 46px rgba(235, 235, 235, 0.5));
      width: 282px !important;
    }
  
    button.book-style {
      background: #FFDD30;
      padding: 18px 100px;
      margin: auto;
      color: #42413D !important;
      font-size: 18px;
      line-height: 21px;
    }
  }