.header-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  min-height: 165px;

  .underNavbar {
    background-color: #FFDD30;
    // height: 30px;
    // display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    text-align: center;
    padding: 12px 10px;
  }

  .mobileHeader {
    display: flex;
    justify-content: center;
    align-items: center;

    .mobileMenuButton {
      top: -27px;
      display: block;
    }

    .menuIcon {
      width: 22px;
    }
  }

  .navLink {
    color: #42413D;
    font-size: 15px;    
    cursor: pointer;

    &:focus, &:hover, &:visited, &:link, &:active {
      text-decoration: none;
    }

    &.active{
      font-weight: 700;
      font-size: 105%;
    }
  }

  .mega-menu-section {
    padding: 35px 10px 30px;

    h5.mega-title {
      font-family: MrsEavesOT-Roman;
      font-size: 22px;
      line-height: 25px;
      letter-spacing: 2.68px;
      color: #42413D;
      font-weight: 400;
      margin-bottom: 20px;
    }

    .navLink {
      display: block;
      line-height: 30px;
    }

    .sub-menu-section {
      margin-right: 70px;

      .inline-menu-section {
        display: inline-block;
        vertical-align: top;
        padding-right: 70px;
        margin-right: 70px;

        &:last-child {
          padding-right: 0;
          margin-right: 0;
        }
      }
    }
  }

  .menu-separator {
    border-bottom: 1px solid #D1D1D1;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .dropdown-container .menu-separator {
    margin: 5px 30px 5px 25px;
  }

  /* The container <div> - needed to position the dropdown content */
  .mega-dropdown {
    position: static;
    padding-bottom: 10px;

    // & > a {
    // }
  }

  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    //display: none;
    position: absolute;
    background-color: #fefefe;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    left: 0;
    width: 100%;
    margin-top: 10px;

  }

  .mega-menu-section.dropdown-content{
    opacity: 1;
    transition: opacity 0.3s;
  }

  /* Show the dropdown menu on hover */
  // .mega-dropdown:hover .dropdown-content {
  //   //display: block;
  // }

  /* The sidebar menu */
  .sidebar {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 100; /* Stay on top */
    top: 0;
    left: 0;
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 0; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
    background-color: #FFFFFF; /* Black*/
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

    .sidebar-header {
      display: flex;
      align-items: center;
      background: #F9F9F9;
      padding: 10px;

      svg {
        fill: #aaa;
      }

      span {
        font-family: URWForm;
        font-size: 15px;
        line-height: 18px;

        color: #42413D;
      }

      a {
        font-family: URWForm;
        font-size: 13px;
        line-height: 15px;
        text-decoration-line: underline;
        padding-left: 10px;
        color: #77756F;
      }
    }

    /* Position and style the close button (top right corner) */
    .closebtn {
      position: absolute;
      top: 1px;
      right: 13px;
      font-size: 30px;
      margin-left: 50px;
      cursor: pointer;
      font-weight: 600;
    }

    /* The sidebar links */
    a, .dropdown-btn {
      padding: 13px 8px 13px 25px;
      text-decoration: none;
      font-size: 15px;
      color: #42413D;
      display: block;
      transition: 0.3s;
      line-height: 20px;
      font-weight: 400;
      background: none;
      border: none;

      /* When you mouse over the navigation links, change their color */
      &:hover {
        color: #041d34;
        text-decoration: none;
      }

      &:active, &:focus {
        outline: none;
      }

      &:not(.active) {
        .fa-caret-down {
          display: inline-block;
        }

        .fa-caret-up {
          display: none;
        }
      }

      &.active {
        .fa-caret-down {
          display: none;
        }

        .fa-caret-up {
          display: inline-block;
        }
      }
    }
  }

  /* The button used to open the sidebar */
  .openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: #111;
    color: white;
    padding: 10px 15px;
    border: none;

    &:hover {
      background-color: #444;
    }
  }

  /* Style page content - use this if you want to push the page content to the right when you open the side navigation */
  #main {
    transition: margin-left .5s; /* If you want a transition effect */
  }

  /* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
  .dropdown-container {
    display: none;
    padding-left: 15px;

    a {
      font-weight: 400;
      color: #71716E;
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }

  /* Optional: Style the caret down icon */
  .fa-caret-down, .fa-caret-up {
    float: right;
    padding-right: 8px;
    margin-left: 15px;
    margin-top: 2px;
  }

  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidebar {
      padding-top: 15px;
    }
    .sidebar a {
      font-size: 18px;
    }
  }

  .support-section {
    background: #F9F9F9;
    height: 100%;
    padding: 20px;
    margin-top: 20px;

    span {
      display: block;

      &.title {
        font-family: DINCondensed-Regular;
        //font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.964706px;
        color: #8B8A86;
      }

      &.phone-number {
        font-family: URWForm;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.0983956px;
        color: #42413D;
      }

      &.open-date-time {
        font-family: URWForm;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.0983956px;
        color: #42413D;
      }
    }

    a {
      font-size: 11px;
      padding: 15px 2px 3px;
    }

    .divider {
      border-bottom: 1px solid #D1D1D1;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    img {
      max-width: 15px;
      height: auto;
    }
  }
}
