.subscribe-page {

  h3 {
    line-height: 1.2;
    margin-bottom: 25px;

    &.card__title__size__small {
      font-size: 0.8rem;
    }

    &.card__title__size__medium {
      font-size: 1.2rem;
    }

    &.card__title__size__large {
      font-size: 2rem;
    }

    &.card__title__size__extra_large {
      font-size: 4rem;
    }

    @media screen and (min-width: 992px) {
      &.card__title__size__small {
        font-size: 1rem;
      }
  
      &.card__title__size__medium {
          font-size: 2rem;
      }
  
      &.card__title__size__large {
          font-size: 3rem;
      }
  
      &.card__title__size__extra_large {
          font-size: 5rem;
      }
    }
  }

  p {
    

    font-family: URWForm;
    font-style: normal;
    font-weight: normal;
    /* or 145% */

    color: #42413D;
    font-size: 15px;
    line-height: 20px;

    @media screen and (min-width: 992px) {
      font-size: 1.375rem;
      line-height: 2rem;
    }
  }

  .container {
    padding-top: 52px;
    padding-bottom: 52px;
    &.Center {
      h3, p {
        text-align: center;
      }
    }

    &.Right {
      h3, p {
        text-align: right;
      }
    }
    
  }

  .top-form-container {
    .container {
      @media screen and (min-width: 1200px) {
        width: 1295px;
      }
    }
    .form-wrapper {
      width: 80%;
      margin: 0 auto;
      max-width: 400px;

      @media screen and (min-width: 768px) {
        max-width: 500px;
      }

      @media screen and (min-width: 992px) {
        max-width: 700px;
      }
    }
  }

  .bottom-form-container {
    border-top: 1px solid #D1D1D1;
    margin-top: 100px;
    padding-bottom: 81px;
    .container {
      max-width: 480px;
      padding-bottom: 48px;
    }
    h3 {
      @media screen and (min-width: 992px) {
        line-height: 1;
        font-size: 39px !important;
        margin-bottom: 23px;
      }
      
      
    }

    p {
      font-size: 17px !important;
      line-height: 23px;
    }

    .form-wrapper {
      max-width: 450px;
      width: 100%;
      margin: 0 auto;
    }
  }
}