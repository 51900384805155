
.contact-container {
  background-color: #F9F9F9;
  padding-bottom: 86px;

  .social-link {
    display: flex;
    align-items: center;
    color: #42413D;

    &:hover {
      color: #7c7c7c;
    }
  }
}

