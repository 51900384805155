.buyout-page.mw-1295 {
  background-color: #f9f9f9;
  max-width: 1295px;

  .banner-section {
    .banner-image {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .buyout-headline {
    & > .row {
      padding-top: 30px;
      padding-bottom: 50px;
      margin-left: 0;
      margin-right: 0;

      h2 {
        font-size: 42px;
      }
    }

    .buyout-headline-body {
      background-color: white;

      .buyout-headline-body-content {
        // ul li, ol li{
        //   list-style: inherit;
        //   display: list-item;
        // }
        p:first-child {
          padding-top: 0;
        }
        p {
          font-size: 18px;
          padding-top: 15px;
        }
      }

      .buyout-headline-body-banner.pl-4 {
        @media screen and (min-width: 768px) {
          border-left: 1px solid #d1d1d1;
        }
      }

      .buyout-headline-body-banner.pr-4 {
        @media screen and (min-width: 768px) {
          border-right: 1px solid #d1d1d1;
        }
      }
    }
  }

  .buyout-get-started {
    .get-started-title {
      border-bottom: 1px solid #d1d1d1;

      p {
        text-align: center;
        font-size: 30px;
        font-weight: bold;
      }
    }

    .get-started-body {
      & > div {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }

      .login-footer {
        background-image: url("../../assets/images/login-footer.png");
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 400px;
        background-size: contain;
        display: flex;

        .stock-up {
          width: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          img {
            width: 25%;
            margin-left: 12%;
          }

          .learn-more-btn {
            text-transform: capitalize;
            width: 23%;
            margin-left: 13%;
            border: 1px solid #d1d1d1;
            margin-top: 20px;

            span {
              top: 0;
            }
          }
        }
      }

      .location-info {
        .select-wrapper {
          background-color: white;
          padding: 38px;
          border-top: 1px solid #d1d1d1;

          .MuiFormControl-root {
            margin-bottom: 25px;
          }
          
          select {
            border: 0;
            outline: 0;
            border-radius: 0;
            border-bottom: 1px solid #d1d1d1;

            &:focus, &:active {
              outline: 0;
              box-shadow: none;
            }
          }
        }

        .button-wrapper {
          display: flex;
          justify-content: center;
        }
      }      
    }
  }

  .location-image img {
    width: 100%;
  }
}