@import "../../../assets/css/media";

.common-modal {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  overflow: scroll;
  flex-wrap: wrap;
  text-align: left;
  position: fixed;

  .modal-container {
    box-shadow: 0 0 64px rgba(0, 0, 0, 0.12);
    background: #fff;
    padding: 28px;
    max-width: 640px;
    box-sizing: content-box;
    margin-top: 20px;
    margin-bottom: 20px;

    @include mobile {
      max-width: 100%;
    }

    .header {
      position: relative;
      // border-bottom: 1px solid #D1D1D1;
      padding-bottom: 10px;

      button {
        position: absolute;
        right: 0;
        top: 0;

        .close-icon {
          font-size: 45px;
          color: #42413D;
          margin: 5px 0;
          width: 25px;
          height: auto;
        }
      }

      .header-text {
        font-size: 31px;
        font-family: 'DINCondensed-Regular';
        line-height: 37px;
        text-transform: uppercase;
        font-weight: 400;
        text-align: center;
      }
    }

    .common-modal-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      box-sizing: border-box;

      .common-modal-message {
        padding: 20px 10px;
        font-size: 1rem;
        font-family: URWForm, DINCondensed-Regular, MrsEavesOT-Roman;
        font-weight: 400;
        line-height: 1.5;
        color: rgba(0, 0, 0, 0.54);
      }

      @include mobile {
        width: 100%;
      }

      .error-msg {
        color: #FF0000;
      }
    }

    .common-modal-footer {
      button {
        font-size: 15px;
        line-height: 117%;
        padding: 15px 50px;
        border-radius: 0;
        text-transform: uppercase;

        &:focus {
          outline: none;
        }
      }
    }
  }
}


