$textColor: darken(white, 3%);
$textHoverColor: white;

@mixin noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

.appStoreLink {
    display: flex;
    flex-direction: row;
    color:$textColor;
    background-color: black;
    width: 148px;
    height: 40px;
    box-shadow: 0px 0px 1px 1px #000000;
    border-radius: 3px;
}

.link {
    @include noselect();
    display: flex;
    flex-direction: row;
    flex: 1;
    color:$textColor;
}

.link:hover {
    color: $textColor;
    font-style: normal;
    text-decoration: none;
    cursor: pointer;
}

.link:visited {
    color:$textColor;
    font-style: normal;
    text-decoration: none;
}

.link:active {
    color:$textColor;
    font-style: normal;
    text-decoration: none;
}

.logo {
    display: flex;
    flex-direction: column;
    width: 38px;
    max-width: 38px;
    align-content: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
    flex:1;
}

.icon {
    display: flex;
    flex-direction: column;
    font-size: 32px !important;
}

.text {
    display: flex;
    flex-direction: column;
    flex:1;
}

.strap {
    display: flex;
    flex-direction: column;
    font-size: 11px;
    text-align: center;
    align-content: flex-end;
    height: 11px;
    max-height: 11px;
}

.name {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

